// Dependencies.
import React from 'react'
import {Link} from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Components.
const Container = styled.div`
  color: white;
  display: inline-block;
  width: 366px;
  position: relative;
  height: 50px;
  margin: auto;
  margin-left: -34px;
  margin-top: calc(30px + 5vmin);
`
const H1 = styled.h1`
  color: #876A47;
  font-family: serif;
  font-size: 50px;
  -webkit-text-stroke: 1px #876A47;
`
const TextBlock = styled(H1)`
  margin-top: -35px;
`
const Barrier = styled.div`
  height: 15px;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
`
const TextOutline = styled(H1)`
  color: transparent;
  left: 0;
  position: absolute;
  top: 0;
  background: url('https://images.unsplash.com/photo-1526202661503-4306bf91df05?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80') no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100%;
`

const ForTheEnvironment = styled.h3`
  position: absolute;
  top: 100%;
  right: -34px;
  color: #333333;
  background: url('https://images.unsplash.com/photo-1526202661503-4306bf91df05?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80') no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 150%;
`

// Header.
export const Header: React.FC = props => {

  // Globals.
  const header = 'Animal Free Day'
  const subheader = 'for the environment'

  // ..
  return <Container>

    {/* Header. */}
    <TextOutline>{header}</TextOutline>
    <Barrier>
      <TextBlock>{header}</TextBlock>
    </Barrier>

    {/* Subheader. */}
    <ForTheEnvironment>{subheader}</ForTheEnvironment>

  </Container>

}