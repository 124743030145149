// Dependencies.
import React from 'react'
import styled from 'styled-components'
import {AFarmWith2500Cows} from './AFarmWith2500Cows'

// Components.
const Section = styled.section`
  margin-top: calc(50px + 10vmin);
  display: inline-block;
`

// Section 1.
export const Section1: React.FC = () => {

  // ..
  return <Section>
    <AFarmWith2500Cows/>
  </Section>

}