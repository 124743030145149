// Dependencies.
import React from 'react'
import {Link} from 'gatsby'

// Components.
import Image from '../components/image'
import Page from '../components/Page'
import SEO from '../components/seo'
import {Header} from '../components/header'
import {CTA} from '../components/CTA'
import {Section1} from '../components/Section1'
import {GlobalStyle} from '../components/GlobalStyle'

// Index Page.
const IndexPage: React.FC = () => {

  // ..
  return <Page>

    <GlobalStyle/>

    {/* SEO. */}
    <SEO title="Home"/>

    {/* Header. */}
    <Header/>

    {/* CTA. */}
    <CTA/>

    {/* Section 1. */}
    <Section1/>

  </Page>
  
}

export default IndexPage
