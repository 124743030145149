// Dependencies.
import React from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'
import {useStaticQuery, graphql} from 'gatsby'

// Components.
const Container = styled.div`
  position: relative;
`
const Part1 = styled.div`
  position: relative;
  .aFarmWith {
    width: 105px;
    ::after {
      right: 0;
    }
  }
  .twentyFiveHundred {
    margin-left: 30px;
    width: 119px;
    margin-bottom: 10px;
  }
  .dairyCows {
    width: 85px;
    margin-left: 70px;
  }
  .cowImage {}
`
const Part2 = styled.div`
  position: relative;
  margin-left: -120px;
  width: 207px;
  .producesTheSame {
    display: inline-block;
    margin-top: 15px;
    width: 155px;
  }
  .aCityOf {
    position: absolute;
    left: 100%;
    top: 20px;
    .cityOf {
      width: 75px;
    }
    .foundHundredThousand {
      margin-left: 30px;
      width: 147px;
    }
    .people {
      width: 64px;
      margin-left: 100px;
    }
  }
`

const CowImage = styled(Image)`
  position: absolute;
  left: 90%;
  top: 0;
  position: absolute;
  left: 100%;
  width: 184px;
  height: 121.29px;
  margin-top: -120px;
`

const Why = styled.h1`
  position: absolute;
  right: 140%;
  height: 100px;
  width: 210px;
  font-size: 100px;
  font-family: serif;
  color: white;
  -webkit-text-stroke: 1px #394038;
  top: -50px;
` 

// A Farm With 2500 Cows.
export const AFarmWith2500Cows = () => {
  
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: {eq: "cow.png"}) {childImageSharp {fluid(maxWidth: 300) {...GatsbyImageSharpFluid}}}
    }
  `)

  // ..
  return <Container>


    {/* Why. */}
    <Why>Why</Why>

    <a 
      className="link" 
      href="https://nepis.epa.gov/Exe/ZyNET.exe/901V0100.txt?ZyActionD=ZyDocument&Client=EPA&Index=2000%20Thru%202005&Docs=&Query=&Time=&EndTime=&SearchMethod=1&TocRestrict=n&Toc=&TocEntry=&QField=&QFieldYear=&QFieldMonth=&QFieldDay=&UseQField=&IntQFieldOp=0&ExtQFieldOp=0&XmlQuery=&File=D:%5CZYFILES%5CINDEX%20DATA%5C00THRU05%5CTXT%5C00000011%5C901V0100.txt&User=ANONYMOUS&Password=anonymous&SortMethod=h%7C-&MaximumDocuments=1&FuzzyDegree=0&ImageQuality=r75g8/r75g8/x150y150g16/i425&Display=hpfr&DefSeekPage=x&SearchBack=ZyActionL&Back=ZyActionS&BackDesc=Results%20page&MaximumPages=1&ZyEntry=1&slide"
      target="_blank"
    >

      <Part1>
        <div className="aFarmWith yellow">A <b>farm</b> with</div>
        <div className="figure twentyFiveHundred yellow">2500</div>
        <div className="dairyCows yellow">dairy cows</div>
        <CowImage className="cowImage" fluid={data.placeholderImage.childImageSharp.fluid}/>
      </Part1>

      <Part2>

        <div className="producesTheSame">
          produces the same amount of waste as
        </div>

        <div className="aCityOf">
          <div className="cityOf purple">a <b>city</b> of</div>
          <div className="figure foundHundredThousand purple">411,000</div>
          <div className="people purple">people</div>
        </div>

      </Part2>

    </a>

  </Container>

}