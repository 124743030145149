import {createGlobalStyle} from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  * {
    color: #333333;
  }
  body {
    text-align: center;
  }
  .yellow {
    position: relative;
    z-index: 1;
    &::before {
      content: '';
      position: absolute;
      left: -20%;
      right: -20%;
      width: 138%;
      background: #F9D692;
      height: 128%;
      top: -10%;
      bottom: -10%;
      z-index: -1;
    }
  }
  .purple {
    position: relative;
    z-index: 1;
    &::before {
      content: '';
      position: absolute;
      left: -20%;
      right: -20%;
      width: 138%;
      background: #E8A8DA;
      height: 128%;
      top: -10%;
      bottom: -10%;
      z-index: -1;
    }
  }
  .figure {
    line-height: 40px;
    font-size: 35px;
    font-weight: 600;
    z-index: 100;
  }
  a {
    color: #333333;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      * {
        text-decoration: underline;
      }
    }
  }
`