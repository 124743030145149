// Dependencies.
import React from 'react'
import styled from 'styled-components'

// Components.
const Container = styled.div`
  margin-top: calc(50px + 7.5vmin);
`
const P = styled.p`
  font-size: 20px;
  font-weight: 500;
  margin: 0;
`
const H4 = styled.h4`
  display: inline-block;
  margin: 0;
  margin-top: 10px;
  position: relative;
  z-index: 1;
  margin-right: -2px;
  ::before {
    content: '';
    position: absolute;
    left: -12.5px;
    width: calc(100% + 20px);
    height: calc(150%);
    top: -25%;
    padding: 10px;
    background: #F9D692;
    background-size: 100%;
    z-index: -1;
  }
`

// Props.
interface Props {}

// CTA.
export const CTA: React.FC<Props> = props => {

  // ..
  return <Container>
    <P>Take a break from eating animals</P>
    <H4>First Friday, monthly.</H4>
  </Container>

}